export const colors = {
    purple: "#721A9F",
    darkPurple: "#2A134E",
    backgroundGray: "#F3F3F6",
    pinkFlirt: "#FF1ABA",
    grey2: "#E0DDE5",
    grey3: "#CDC8D5",
    grey5: "#A89FB7",
    grey7: "#61507C",
    heaven: "#0085C6",
    sunshine: "#FFB100",
    grass: "#80A33F",
    clementine: "#F7781F",
    lollipop: "#FF0D0D",
    bear: "#AA5E1C",
    accentDimples: "#ff6ad2",
    white: "#fff",
}

export type Color = keyof typeof colors

/**
 * This new helper will return an appropriate text color for a given
 * background color that is a part of the defined color palette.
 *
 * @param backgroundColor The background color to get the text color for.
 * @returns The text color that will be most readable on the given background color.
 */
export function getTextColorForBackgroundColor(backgroundColor: Color) {
    switch (backgroundColor) {
        case "purple":
        case "darkPurple":
        case "pinkFlirt":
        case "grey7":
        case "heaven":
        case "sunshine":
        case "grass":
        case "clementine":
        case "lollipop":
        case "bear":
        case "accentDimples":
            return colors.white
        default:
            return colors.darkPurple
    }
}

export const gradients = {
    purpleToDarkPurple: `linear-gradient(
        44deg,
        ${colors.darkPurple} -4.7%,
        ${colors.purple} 100.7%
    )`,
}

export const shadows = {
    icon: "filter: drop-shadow(0 3px 3px rgb(0 0 0 / 0.2));",
}

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { capitalizeFirstLetter } from "../../../../../reactor/Helpers"
import { DateString } from "../../../../../reactor/Types/Primitives"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { NonNegativeInteger } from "../../../../../reactor/Types/Primitives/NonNegativeNumber"
import { Check } from "../../assets/Check"
import { postPartyBooking, usePartyPrice } from "../../client"
import { colors } from "../../colors"
import { usePageContext } from "../../Page"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../../packages/localization/client-side/useLocalize"
import { CallToAction } from "../TextAndCallToAction"
import { Field } from "./Field"
import { usePartyContext } from "./PartyContext"

export function SummaryPage() {
    const localize = useLocalize()
    const { venue } = usePageContext()
    const locale = useCurrentLocale()
    const {
        section,
        guestCount,
        extras,
        partyPackage,
        mealOption,
        date,
        time,
        roomName,
        notes,
        nameOfTheBirthdayChild,
        birthDate,
        email,
        acceptedTOC,
        setAcceptedTOC,
        firstName,
        lastName,
        phoneNumber,
        room,
    } = usePartyContext()
    const navigate = useNavigate()

    const [verifiedInfo, setVerifiedInfo] = useState(false)

    const partyExtras: { id: Uuid<"PartyExtra">; quantity: NonNegativeInteger }[] = []
    for (const id in extras) {
        partyExtras.push({ id: id as any, quantity: NonNegativeInteger(extras[id]) })
    }

    if (!venue) return <div>No venue active</div>

    const { data } = usePartyPrice(
        partyPackage
            ? {
                  venueId: venue.id,
                  numberOfChildren: NonNegativeInteger(guestCount),
                  partyExtras,
                  partyPackage: partyPackage.id,
              }
            : null
    )

    if (!data) return <div>Unable to calculate price summary</div>
    const currency = data.currency

    const yearsOld = Math.round(
        new Date().getFullYear() - new Date(birthDate?.valueOf() ?? 0).getFullYear()
    )

    const daysFromNow = Math.round(((date?.getTime() ?? 0) - Date.now()) / 1000 / 60 / 60 / 24)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                marginBottom: 64,
            }}>
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <h2>
                    <EditableText
                        obj={section}
                        prop="summaryThanks"
                        defaultText="Awesome, thanks!"
                        isLocalized={true}
                    />
                </h2>
                <EditableText
                    obj={section.summaryPage}
                    prop="subtext"
                    style={{ margin: 16 }}
                    macros={{
                        email: <span style={{ color: colors.pinkFlirt }}>{email}</span>,
                        child: <span className="bold">{nameOfTheBirthdayChild}</span>,
                        fromNow: date
                            ? new Intl.RelativeTimeFormat(locale.valueOf(), {
                                  numeric: "auto",
                              }).format(
                                  daysFromNow > 60
                                      ? daysFromNow / 30
                                      : daysFromNow > 14
                                        ? Math.round(daysFromNow / 7)
                                        : daysFromNow,
                                  daysFromNow > 60 ? "months" : daysFromNow > 14 ? "weeks" : "days"
                              )
                            : undefined,
                    }}
                />
            </div>
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 16,
                }}>
                <Field section={section.summaryPage} prop="birthdayChild">
                    <OrderLine
                        primary={`${nameOfTheBirthdayChild} ${
                            yearsOld === 0 || yearsOld > 18
                                ? ""
                                : `(${yearsOld} ${localize(yearsOldPostfix)})`
                        }`}
                    />
                </Field>
                <Field section={section.summaryPage} prop="dateAndTime">
                    <OrderLine
                        primary={
                            capitalizeFirstLetter(
                                new Intl.DateTimeFormat(locale.valueOf(), {
                                    weekday: "long",

                                    year: "numeric",
                                    day: "2-digit",
                                    month: "long",
                                }).format(date)
                            ) +
                            ", " +
                            time
                        }
                    />
                </Field>
                <Field section={section.summaryPage} prop="roomAndLocation">
                    <OrderLine primary={`${roomName} - ${localize(venue.name)}`} />
                </Field>
                <Field section={section.summaryPage} prop="partyPackage">
                    <OrderLine primary={`${localize(data.partyPackage.name)}`} />
                </Field>
                <Field section={section.summaryPage} prop="numberOfChildren">
                    <OrderLine
                        primary={`${data.numberOfChildren}`}
                        secondary={`x ${localize(data.partyPackage.name)} -  ${getFormattedPrice(
                            data.partyPackage.pricePerChild.valueOf()
                        )}`}
                        price={getFormattedPrice(data.partyPackageTotal.valueOf())}
                    />
                </Field>
                {mealOption && (
                    <Field section={section.summaryPage} prop="mealOption">
                        <OrderLine primary={localize(mealOption?.name) ?? "(no meal chosen)"} />
                    </Field>
                )}

                <Field section={section.summaryPage} prop="extras">
                    {data?.partyExtras.map((extra) => (
                        <OrderLine
                            primary={localize(extra.name) ?? "??"}
                            secondary={`x ${extra.quantity}`}
                            price={getFormattedPrice(
                                (extra.price.valueOf() +
                                    extra.pricePerChild.valueOf() *
                                        data.numberOfChildren.valueOf()) *
                                    extra.quantity.valueOf()
                            )}
                        />
                    ))}
                    {data.partyExtras.length === 0 ? (
                        <div style={{ marginBottom: 16 }}>Ingen extras</div>
                    ) : null}
                </Field>
                <Field section={section.summaryPage} prop="notes">
                    <OrderLine primary={notes ?? ""} />
                </Field>

                <div
                    style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 32 }}>
                    <h3 style={{ flex: 1 }}>Total</h3>
                    <h3>{getFormattedPrice(data.totalWithVat.valueOf())}</h3>
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <EditableText
                        obj={section.summaryPage}
                        prop="includingVAT"
                        defaultText="Including VAT"
                        style={{ flex: 1 }}
                    />
                    <div>{getFormattedPrice(data.vat.valueOf())}</div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 32 }}>
                    <CheckBox value={verifiedInfo} onChange={setVerifiedInfo} />
                    <EditableText
                        obj={section}
                        prop="iHaveVerifiedTheInformation"
                        defaultText="I double checked the information"
                        isLocalized={true}
                        isMarkdown={true}
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CheckBox value={acceptedTOC} onChange={setAcceptedTOC} />
                    <EditableText
                        obj={section}
                        prop="iHaveReadAndAcceptTOC"
                        defaultText="I have read and accept
                    the terms and conditions"
                        isLocalized={true}
                        isMarkdown={true}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 64,
                        width: "100%",
                        justifyContent: "center",
                    }}>
                    <CallToAction
                        disabled={!acceptedTOC}
                        section={section}
                        prop="bookNowCallToAction"
                        onClick={async () => {
                            try {
                                // This shouldn't happen, but just in case, and to make typescript happy
                                if (!email) throw new Error("Missing contact email")
                                if (!firstName) throw new Error("Missing contact first name")
                                if (!lastName) throw new Error("Missing contact last name")
                                if (!phoneNumber) throw new Error("Missing contact phone number")
                                if (!date) throw new Error("Missing date")
                                if (!room) throw new Error("Missing room")
                                if (!birthDate) throw new Error("Missing birth date")

                                const response = await postPartyBooking("Website", {
                                    locale,
                                    contactEmail: email,
                                    contactFirstName: firstName,
                                    contactLastName: lastName,
                                    contactPhone: phoneNumber,
                                    date: DateString(date),
                                    numberOfChildren: NonNegativeInteger(guestCount),
                                    roomId: room.id,
                                    venueId: venue.id,
                                    timeSlot: time as any,
                                    additionalInfo: notes,
                                    birthdayChildName: nameOfTheBirthdayChild,
                                    birthdayDate: DateString(birthDate?.valueOf()),
                                    partyPackageId: data.partyPackage.id,
                                    partyMeal: mealOption?.id,
                                    partyExtraIds: data.partyExtras.map((extra) => ({
                                        id: extra.id,
                                        quantity: extra.quantity,
                                    })),
                                    paymentTotal: data.totalWithVat,
                                })

                                navigate(`../confirmation/party-booking-order/${response.id}`)
                            } catch (e: any) {
                                if ("message" in e) alert(e.message)
                                else if ("detail" in e) alert(e.detail)
                                else alert("Something went wrong")
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
    function getFormattedPrice(price: number) {
        return new Intl.NumberFormat(locale.valueOf(), {
            currency,
            style: "currency",
            maximumFractionDigits: 0,
        }).format(price)
    }
    function getFormattedPercent(price: number) {
        return new Intl.NumberFormat(locale.valueOf(), {
            style: "percent",
            maximumFractionDigits: 1,
        }).format(price)
    }
}

const yearsOldPostfix = {
    no: "år",
    en: "years old",
    sv: "år",
    dk: "år",
    de: "Jahre alt",
}

function CheckBox({ value, onChange }: { value: boolean; onChange: (v: boolean) => void }) {
    return (
        <div
            onClick={() => onChange(!value)}
            style={{
                width: 24,
                height: 24,
                backgroundColor: value ? colors.darkPurple : "white",
                borderRadius: 4,
                border: `1px solid ${colors.darkPurple}`,
                marginRight: 12,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            {value ? <Check /> : undefined}
        </div>
    )
}

function OrderLine({
    primary,
    secondary,
    price,
}: {
    primary: string
    secondary?: string
    price?: string
}) {
    return (
        <div
            style={{
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                width: "100%",
                borderRadius: 8,
                backgroundColor: "white",
                marginTop: 8,
                display: "flex",
                flexDirection: "row",
                minHeight: 48,

                alignItems: "center",
            }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", flex: 1 }}>
                {primary}
                <div style={{ marginLeft: 8, fontSize: 14, color: colors.grey5 }}> {secondary}</div>
            </div>
            {price}
        </div>
    )
}
